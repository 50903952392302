<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <set-message @publish="handlePublish" :text="{ t1: '消息名称', t2: '消息内容', t3: '文本信息' }" users="users" messageCont="messageContents" :form="form">
    <!-- <template slot="appoint-crowd">
        <el-form-item label="使用客户">
          <el-button @click="selectCustomers">选择客户</el-button>
        </el-form-item>
      </template>-->
    </set-message>
  </div>
</template>
<script>
import { saveMessage } from '@/api/data/wechat/customer/message'
import { mapState } from 'vuex'
import setMessage from "@/components/local/setMessage";
export default {
  data() {
    return {
      crumbs: new Map([
          ['数据中台'], ['企业微信'], ['客户管理'], ['消息群发', '/mass_message'], ['创建群发']
      ]),
      form: {}
    }
  },
  components: { setMessage },
  computed: { ...mapState(['hotelInfo']) },
  methods: {
    // 保存发布
    handlePublish(ruleForm) {
      const form = Object.assign({}, this.form, ruleForm)
      const params = { ...form, hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
      saveMessage(params).then(({ success }) => {
        if (!success) return
        this.$router.push('/mass_message')
        this.$message({ message: '保存成功！', type: 'success' })
      })
    }
  }
}
</script>
