import request from "@/api/service";

const api = {
   page: '/event/enterpriseWechat/messagePush/getPage',
   add: '/event/enterpriseWechat/messagePush/add',
   send: '/event/enterpriseWechat/messagePush/sendMessage',
   remove: '/event/enterpriseWechat/messagePush/remove'
}

export function getPage(query, params) {
   return request({
      url: api.page + query,
      method: 'POST',
      data: params
   })
}

export function saveMessage(params) {
   return request({
      url: api.add,
      method: 'POST',
      data: params
   })
}

export function sendMessage(params) {
   return request({
      url: api.send,
      method: 'POST',
      data: params
   })
}

export function removeMessage(params) {
   return request({
      url: api.remove,
      method: 'POST',
      data: params
   })
}
